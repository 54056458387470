const storageKeysPrefix = 'adt_';

export const CONSTANTS = {
  storageKeys: {
    returnUri: `${storageKeysPrefix}return_uri`,
    authState: `${storageKeysPrefix}auth_state`,
    authType: `${storageKeysPrefix}auth_type`,
    otpAuthManager: `${storageKeysPrefix}otp_auth_manager`,
    createConsentRedirect: `${storageKeysPrefix}ccr`,
    manageConsentRedirect: `${storageKeysPrefix}mcr`,
    appErrorRedirect: `${storageKeysPrefix}aer`,
  },
  urlParamsKeys: {
    createConsentRedirect: 'ccr',
    manageConsentRedirect: 'mcr',
    appErrorRedirect: 'aer',
  },
  errors: {
    userOptOut: {
      code: 'usr_opt_out',
      description: 'User manually opted out of consent',
    },
  },
};
